import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
    apiKey: "AIzaSyCRhIXsFiN4VdZlpbMKFoz1KOt57q5X6BI",
    authDomain: "client-requirements.firebaseapp.com",
    databaseURL: "https://client-requirements-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "client-requirements",
    storageBucket: "client-requirements.appspot.com",
    messagingSenderId: "401978525624",
    appId: "1:401978525624:web:61aded8aac6a39090db14d",
    measurementId: "G-MJRDL8S6DX"
};

const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);